#login-title {
    margin: 3em 0 1em 0;
}

/* Small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
    #login-title {
        margin: 32px 0 16px 0;
    }

    #login-title small {
        font-size: 60%;
    }
}