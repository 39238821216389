.search-input {
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    border: 1px solid darkgray;
    padding: 0;
    overflow: hidden;
    min-width: 30rem;
}

.search-input input {
    border: 0;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    width: 100%;
}

.search-input input:focus {
    outline: none;
}

.search-input button {
    border: 0;
    border-left: 1px solid darkgray;
    min-width: 3rem;
}