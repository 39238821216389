.main-background-color {
    background-color: #005aa9;
}

.main-border-color {
    border-color: #005aa9;
}

.main-text-color {
    color: #005aa9;
}

.light-background-color {
    background-color: white;
}

.light-text-color {
    color: white;
}

.dark-text-color {
    color: #222222;
}

.title {
    display: flex;
    width: 100%;
    padding: 0;
    font-weight: bold;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
}

.text-extra-large {
    font-size: 1.8rem;
}

.text-larger {
    font-size: 1.4rem;
}

.text-large {
    font-size: 1.2rem;
}

.text-regular {
    font-size: 1rem;
}

.text-small {
    font-size: 0.9rem;
}

.text-smaller {
    font-size: 0.8rem;
}

.text-normal {
    font-style: normal;
    font-weight: normal;
    text-transform: none;
}

.horizontal-center {
    display: flex;
    width: 100%;
    justify-content: center;
}

.vertical-center {
    display: flex;
    align-items: center;
}

.no-margin {
    margin: 0;
}

.no-padding {
    padding: 0;
}

.full-width {
    width: 100%;
}

/* Galaxy Fold */
@media screen and (max-width: 320px) {
    .text-extra-large {
        font-size: 1.3rem;
    }

    .text-larger {
        font-size: 1.2rem;
    }

    .text-large {
        font-size: 1.1rem;
    }
}

/* Small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) and (min-width: 321px) {
    .text-extra-large {
        font-size: 1.6rem;
    }

    .text-larger {
        font-size: 1.3rem;
    }

    .text-large {
        font-size: 1.1rem;
    }
}
