.login-button-container {
    position: relative;
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    height: 2.5rem;
}

.login-button-container div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.login-button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.login-button button {
    height: 2.5rem;
    min-width: 180px;
    font-weight: bold;
    border: 0;
    padding: 0 1rem;
}

.login-button-small {
    display: inline-flex;
    margin: 0;
    padding: 0;
}

.login-button-small button {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: bold;
}

.login-auxiliary-button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.login-auxiliary-button button {
    height: 2rem;
    min-width: 180px;
    font-weight: bold;
    border: 0;
    padding: 0;
    text-transform: uppercase;
}

.login-activation-button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.login-activation-button button {
    height: 2rem;
    min-width: 250px;
    font-weight: bold;
    border: 0;
    padding: 0;
    text-decoration: underline;
}