.book-side-menu {
    position: fixed;
    display: inline-block;
    top: 15rem;
    z-index: 9999;
}

.book-side-menu-button {
    display: inline-flex;
    align-content: flex-start;
    padding: 0.4rem 0 0.4rem 0.6rem;
    margin-bottom: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    font-size: 1.3rem;
    line-height: 1.3rem;
    height: 3rem;
    min-width: 2.5rem;
}

.book-side-menu-button button {
    width: 100%;
    text-align: left;
    padding-right: 0.6rem;
}

.button-wide {
    width: 10rem;
}

.book-side-menu-button #logo img {
    padding: 0;
    max-height: 2rem;
}

.book-side-menu-button button {
    border: 0;
}

@media screen and (max-width: 768px) {
    .book-side-menu {
        display: none;
    }
}