#navbar {
    transition: top 0.3s; /* Transition effect when sliding down (and up) */
    height: 4em;
    z-index: 9999;
}

.navbar-hide {
    top: -4em;
}

.navbar-show {
    top: 0;
}

.logo {
    padding: 0;
    margin: 0 5em 0  0;
    height: 2em;
}

.logo #logo img {
    padding: 0;
    margin: 0;
    height: 2em;
    width: auto;
}

.nav-item {
    text-align: center;
}

#responsive-navbar-nav {
    margin: 0 -16px;
    padding: 0 10px;
}

a span:hover {
    color: #e4f2ff;
}

#logout-icon {
    display: inline-flex;
    vertical-align: -0.1em;
}

@media screen and (max-width: 768px) {
    #navbar {
        height: 3em;
        padding: 0 1rem;
    }

    .navbar-hide {
        top: 0;
    }

    nav .navbar-brand {
        font-size: 1rem;
        line-height: 1rem;
    }

    nav .logo {
        margin: 0;
        height: 2em;
    }

    nav .logo #logo img {
        height: 2em;
    }

    nav .navbar-toggler {
        font-size: 1rem;
    }
}