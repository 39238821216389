.login-text-field {
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
}

.login-text-field .container {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.login-text-field label {
    margin: 0;
    font-weight: bold;
}

.login-text-field input {
    border-width: 2px;
    border-style: solid;
    padding: 0 1rem;
    margin: 0;
    width: 100%;
    height: 3rem;
}