.info-button {
    display: block;
    height: 2rem;
    line-height: 1rem;
    background-color: white;
    font-weight: bold;
    border: 0;
    margin: 0 10% 0 10%;
    padding: 0 10px 0 10px;
}

/* Mobile phones */
@media screen and (max-width: 375px) {
    .info-button {
        margin: 0 5% 0 5%;
        font-size: 1rem;
    }
}

/* Galaxy Fold */
@media screen and (max-width: 280px) {
    .info-title {
        font-size: 0.8rem;
    }

    .info-button {
        margin: 0 3px 0 3px;
        font-size: 0.8rem;
    }
}