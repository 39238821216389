/* This stuff is important! Otherwise when you select the text, the text in the divs will show up! */
::selection {
    background: rgba(0, 123, 255, 0.2);
}
::-moz-selection {
    background: rgba(0, 123, 255, 0.2);
}

.document-container {
    position: relative;
    max-width: 1160px;
}

.document-container-header-offset {
    top: 2.5em;
}

.document-container-navbar-and-header-offset {
    top: 6.5em;
}

/* Small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
    .document-container-navbar-and-header-offset {
        top: 5.5em;
    }
}

.no-scrollbars {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.no-scrollbars::-webkit-scrollbar {
    width: 1px;
}

.no-scrollbars::-webkit-scrollbar-track {
    background: transparent;
}

.no-scrollbars::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.no-scrollbars::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}

/* Mobile phones & tablets */
@media screen and (max-width: 1200px) {
    .container-fluid {
        padding: 0;
    }
}

@media only screen and (min-width: 1700px) {
    .root-book-container {
        max-width: 1400px;
        margin-left: 10rem;
    }
}