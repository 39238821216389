.login-form {
    margin: 0;
    padding: 0;
}

.login-title {
    display: flex;
    width: 100%;
    padding: 20px 0 20px 0;
    font-weight: bold;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
}