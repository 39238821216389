.login-container {
    position: relative;
    left: 20%;
    background-color: #ffffff;
    top: 0;
    bottom: 0;
    width: 460px;
    height: auto;
    min-height: 100vh;
    padding-bottom: 2em;
}

@media screen and (max-width: 768px) {
    .login-container {
        left: 50%;
        width: 95%;
        transform: translateX( -50% );
    }
}