#book-activation-dialog-body #login-title {
    margin-top: 1rem;
}

#book-activation-dialog-body .login-text-field {
    margin: 0 1em 0 1em;
    width: auto;
}

#book-activation-dialog-body .activation-info {
    margin: 0 1em 0 1em;
    width: auto;
}