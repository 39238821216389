.books-container {
    max-width: 800px;
}

@media only screen and (min-width: 1380px) {
    .books-container {
        max-width: 1200px;
    }
}

@media only screen and (min-width: 1700px) {
    .books-container {
        max-width: 1200px;
        margin-left: 16rem;
    }
}

@media only screen and (min-width: 2000px) {
    .books-container {
        max-width: 1600px;
        margin-left: 16rem;
    }
}

.book-title {
    color: gray;
    margin-left: 0.5em;
    font-size: 24px;
}

/* Small devices (phones, 576px and down) */
@media only screen and (max-width: 576px) {
    .book-title {
        font-size: 1.2rem;
    }
}

.book-item {
    position: relative;
    margin: 0.5rem;
}

.book-item .shadow {
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.5) !important;
}

.book-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: #888c;
    height: 100%;
    width: 100%;
}

.book-actions-overlay {
    display: none;
}

.book-overlay:hover + .book-actions-overlay, .book-actions-overlay:hover {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.book-item-actions {
    flex-direction: column;
}

.book-item-actions button {
    margin: 0.5rem;
    border: 0;
    background: transparent;
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.slick-track {
    float:left;
}

.slide-arrow {
    position: absolute;
    display: block;
    top: 50%;
    margin-top: -15px;
    background: transparent;
}

.hidden-arrow {
    display: none;
}

.prev-arrow {
    left: -20px;
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 8px solid gray;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
}

.next-arrow {
    right: -20px;
    width: 0;
    height: 0;
    border-right: 0 solid transparent;
    border-left: 8px solid gray;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
}