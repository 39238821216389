.admin-navbar {
    z-index: 999;
}

.admin-navbar-brand {
    font-size: 2rem;
    margin-right: 2rem;
}

.admin-navbar-item {
    margin-right: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}