.bi-search {
    width: 22px;
    height: 22px;
}

.current-topic-no-offset {
    top: 0;
}

.current-topic-offset {
    top: 4em;
}

.current-topic-container {
    position: fixed;
    left: 0;
    height: 2.5rem;
    z-index: 9998;
    background: #fae300;
    transition: top 0.3s;
}

.current-topic {
    display: grid;
    justify-content: left;
}

.current-topic-text {
    font-weight: bold;
    color: #988a00;
    height: 2.5rem;
    line-height: 2.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 16px;
}

.current-topic-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.current-topic-title {
    display: inline;
}

.current-topic-buttons {
    display: none;
}

@media screen and (max-width: 768px) {
    .current-topic-offset {
        top: 3em;
    }

    .current-topic-no-offset {
        top: 3em;
    }

    .current-topic-title {
        display: none;
    }

    .current-topic-buttons {
        display: inline;
        text-align: right;
    }
}