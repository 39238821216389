#bg {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    background: url(../../../resources/images/background.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}