.modal-backdrop {
    z-index: 100000 !important;
}

.modal {
    z-index: 100001 !important;
}

.toc-item {
    border: 0;
    background: transparent;
}

.toc-main-section {
    font-size: 1.4rem;
    font-weight: bold;
    color: #c3222a;
    text-align: left;
    margin-left: 1rem;
    text-indent: -1rem;
}

.toc-section {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: left;
    margin-left: 3rem;
    text-indent: -1rem;
}

.toc-sub-section {
    text-align: left;
    margin-left: 5rem;
    text-indent: -1rem;
}